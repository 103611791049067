/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TTheme } from "@doar/shared/types";

export interface LoginToken {
    email: string | undefined;
    token: string | undefined;
}

const initialState: LoginToken = {
    email: undefined,
    token: undefined,
};

const loginSlice = createSlice({
    name: "userCredentials",
    initialState,
    reducers: {
        getToken(
            state,
            action: PayloadAction<{
                email: string | undefined;
                token: string | undefined;
            }>
        ) {
            const {
                payload: { email, token },
            } = action;

            state.email = email;
            state.token = token;
        },
    },
});

export const { getToken } = loginSlice.actions;
export default loginSlice.reducer;
