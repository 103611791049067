/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

export type Day = string;

const initialState: Day = moment().format("YYYY-MM-DD");

const dayDashboardSlice = createSlice({
    name: "dayDashboard",
    initialState,
    reducers: {
        getDay(state, action: PayloadAction<Day>) {
            const { payload: date } = action;

            state = date;
        },
    },
});

export const { getDay } = dayDashboardSlice.actions;
export default dayDashboardSlice.reducer;
