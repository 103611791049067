/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TTheme } from "@doar/shared/types";
import Closures from "src/module/respAxios";

const initialState: { data: Closures[] } = { data: [] };

const dataMonthlyKpiSlice = createSlice({
    name: "dataMonthlyKpi",
    initialState,
    reducers: {
        getDataMonthlyKpi(state, action: PayloadAction<Closures[]>) {
            const { payload } = action;
            state.data = [...payload];
        },
    },
});

export const { getDataMonthlyKpi } = dataMonthlyKpiSlice.actions;
export default dataMonthlyKpiSlice.reducer;
