import dayjs, { Dayjs } from "dayjs";
import it from "dayjs/locale/it";

/* eslint-disable */
/**
 * Represents a book.
 * @constructor
 * @param {string} date - The title of the book.
 * @param {"s"} type - s: YYYY-MM-DD,  d:YYYY-MM-DD/dddd,  day:dddd,  month:MM,  h:HH:mm,  fullMonth:MMM
 * @returns {string} - formatted date
 */

export const formatDate = (
    date?: string | Date,
    type?:
        | "s"
        | "d"
        | "w"
        | "day"
        | "month"
        | "h"
        | "fullMonth"
        | "startWeek"
        | "forWeekly"
) => {
    dayjs.locale(it);
    const selectedDate = date
        ? type === "forWeekly"
            ? dayjs((date as string).split("/")[0])
            : dayjs(date)
        : dayjs();
    const selectedDate2 =
        type === "forWeekly" ? dayjs((date as string).split("/")[1]) : null;
    switch (type) {
        case "s":
            return selectedDate.format("DD/MM/YYYY");
        case "d":
            return selectedDate.format("DD/MM/YYYY - dddd");
        case "day":
            return selectedDate.format("DD");
        case "month":
            return selectedDate.format("MM");
        case "fullMonth":
            return selectedDate.format("MMM");
        case "h":
            return selectedDate.format("HH:mm");
        case "startWeek":
            return selectedDate.startOf("week").add(1, "day").format("DD");
        case "forWeekly":
            if (selectedDate.format("MM") === selectedDate2?.format("MM")) {
                return `${selectedDate2.format("DD")}/${selectedDate.format(
                    "DD"
                )}`;
            } else {
                return `${selectedDate.format("DD")}-${selectedDate2?.format(
                    "DD/MM/YYYY"
                )}`;
            }

        default:
            return selectedDate.format("DD/MM/YYYY");
    }
};

/*eslint-disable */

// Create our number formatter.
export const formatCurrency = (number: number | string) =>
    new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
    }).format(Number(number));

export const formatPercentual = (number: number | string) =>
    new Intl.NumberFormat("de-DE", {
        style: "percentual",
        currency: "EUR",
    }).format(Number(number));

console;

export const percentual = (number: number | string) => `${number}%`;
export const currency = (number: number | string) =>
    number ? `€${number.toString()}` : "€0";
