/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TTheme } from "@doar/shared/types";
import Closures from "src/module/respAxios";
import { formatDate } from "src/utils/formatter";

const initialState: { data: Closures[]; date: string } = {
    data: [],
    date: formatDate(),
};

const dataYearlyKpiSlice = createSlice({
    name: "dataYearlyKpi",
    initialState,
    reducers: {
        getDataYearlyKpi(state, action: PayloadAction<Closures[]>) {
            const { payload } = action;
            state.data = [...payload];
            state.date = formatDate();
        },
    },
});

// var midnight = new Date();
// midnight.setHours(23,59,59,0);

// $.cookie('anewsletter', '1', { expires: midnight });

export const { getDataYearlyKpi } = dataYearlyKpiSlice.actions;
export default dataYearlyKpiSlice.reducer;
